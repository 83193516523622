import { createStore } from 'zustand-x';

export const languageStore = createStore('language')(
  {
    language: 'en',
  },
  { devtools: { enabled: true }, persist: { enabled: true } }
).extendActions((set) => ({
  toggle: (lang: string) => {
    set.language(lang);
  },
}));
