import '@core/utils/wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'leaflet/dist/leaflet.css';
import './index.css';
// aimport '@fontsource/lato';
import { ApolloProvider } from '@apollo/client';
import client from '@core/lib/apolloClient/apolloClient';

const App = React.lazy(() => import('./App'));

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
