import { createStore } from 'zustand-x';

export const sidebarStore = createStore('sidebar')(
  {
    isOpen: false,
  },
  { devtools: { enabled: true } }
).extendActions((set, get) => ({
  open: () => {
    set.isOpen(true);
  },
  close: () => {
    set.isOpen(false);
  },
  toggle: () => {
    set.isOpen(!get.isOpen());
  },
}));
