import { createStore } from 'zustand-x';

export const themeStore = createStore('theme')(
  {
    isDarkMode: false,
    theme: 'light',

    isRTL: false,
  },
  { devtools: { enabled: true }, persist: { enabled: true } }
).extendActions((set, get) => ({
  toggle: () => {
    set.isDarkMode(!get.isDarkMode());
  },
}));
